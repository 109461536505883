import React, { useEffect } from "react";
import ReactGA from "react-ga4";
const Landinpage = () => {
  useEffect(() => {
    ReactGA.initialize("G-MZ4TH2Y2XE");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/phantomzzz", "_blank");
  };
  return (
    <div
      onClick={handleonclick}
      className="w-full h-screen bg-no-repeat bg-cover relative"
      style={{
        backgroundImage:
          "url('/WhatsApp Image 2024-12-09 at 19.07.12_9ad88a36.jpg')",
      }}
    >
      <img
        className="absolute inset-0 md:top-0   md:px-44 z-5 w-full  md:h-screen"
        src="/Card.png"
        alt=""
      />
      <div className="absolute inset-0 md:top-32 top-10 rotate-180   z-35">
        <img className=" object-cover" src="/overly image.png" alt="" />
      </div>
      <div className="absolute inset-0 md:top-32  top-44  md:w-2/3 md:left-[17vw]  z-35">
        <h1 className="text-yellow-500 font-bold uppercase md:text-7xl  text-5xl text-center font-[monstrat]">
          Bet First, Get 100% Cashback{" "}
        </h1>
        <h1 className="text-white font-bold uppercase md:text-5xl  mt-5  text-3xl text-center">
          10% deposit bonus!{" "}
        </h1>
        <div>
          <button className="bg-white border border-black md:px-32 md:ml-[20vw] ml-10 bg-gradient-to-b from-yellow-500 via-white to-yellow-500   px-20 rounded-full text-2xl uppercase font-bold py-3 mt-10">
            Join us now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Landinpage;
