import './App.css';
// import Homepage from './components/ChocoTreats/Homepage';
// import Homepage from './components/Phantomvideos/hompage';
import Landinpage from './metaads/Landinpage';

function App() {
  return (
    <div className="App">
       <Landinpage/>
    </div>
  );
}

export default App;
